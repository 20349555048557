import React from "react"
import Header from "../components/header";
import {PageContainer, Container} from "../components/container";
import "./index.scss";
import card1 from "../images/card1.svg";
import card2 from "../images/card2.svg";
// import SubscriptionForm from "../components/subscriptionForm";
import SEO from "../components/seo";
import Picture1 from "../images/picture1.jpg";
import Picture2 from "../images/picture2.jpg";
import Picture3 from "../images/picture3.jpg";
import Picture4 from "../images/picture4.jpg";
import { OutboundLink } from 'gatsby-plugin-gtag'
import { useStaticQuery, graphql } from "gatsby"

import Logo from "../components/logo";
const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            typeform
            url
          }
        }
      }
    `
  )

  return (
    <PageContainer>
      <div role="presentation">
      <Container>
        <div className="stripe stripe-1"></div>
        <div className="stripe stripe-2"></div>
        <div className="large-bg"></div>
        </Container>
      </div>
        
      <div id="homePage" className="homePage">

        <SEO title="MyShoots - All the tools a photographer needs" />
        <Header />
        <Container>

          <div className="banner">
            <div className="banner-left">
              <div className="page-title">
                <p className="banner-subtitle">
                  All the tools a photographer needs
                </p>
                <h1 className="banner-title">
                  A Suite designed for you <br />and your photography business
                </h1>
              </div>

              <div className="archive-info">
                <h3 className="archive-title">Comes with UNLIMITED archive space</h3>
                <p className="archive-text">
                  Ever wanted to save every single shoot you take?
                  <br />
                  Now you can!
                  <br />
                  <span className="button-wrapper">
                  <OutboundLink href={site.siteMetadata.typeform} type="submit" className="subscription-button-action">
                  Apply for the early beta                   
                  </OutboundLink>
                  </span>
                </p>

              </div>
              {/* <SubscriptionForm /> */}
  
            </div>
            <div className="banner-right">
              <div className="cards">
                <img src={card1} alt="Album Preview 1" className="card1 card" />
                <img src={card2} alt="Album Preview 2" className="card2 card" />
              </div>
            </div>
          </div>
        </Container>

        <div className="page-content">
          <Container>
            <h2>We are coming up with series of tools to speed up your photography business</h2>
            <div className="page-conent-wrapper">
            </div>
          </Container>
          <Container>
            <div className="page-section">
              <div className="page-section-media">
                <img src={Picture1} alt="" className="feature-image" />
              </div>
              <div className="page-section-content">
                <div className="page-section-content-wrapper">
                <h3>
                    UNLIMITED CLOUD space to archive your shoots forever
                  </h3>
                  <p>
                  Say goodbye to storage devices. Keep every single shoot you take, as long as you want, on a secure cloud.
                  </p>

                  <a href="https://aws.amazon.com/what-is-cloud-computing"><img width="240" src="https://d1.awsstatic.com/logos/aws-logo-lockups/poweredbyaws/PB_AWS_logo_RGB.61d334f1a1a427ea597afa54be359ca5a5aaad5f.png" alt="Powered by AWS Cloud Computing"/></a>
                </div>
              </div>

            </div>
          </Container>
          <Container>
            <div className="page-section right">
              <div className="page-section-media">
                  <img src={Picture2} alt="" className="feature-image" />
                </div>
              <div className="page-section-content">

                <div className="page-section-content-wrapper right">
                <h3>
                    Collect inquries and manage your leads in a single place
                  </h3>
                  <p>
                  An incredible CRM designed around photographers needs. It has never been easier to run your business!
                  </p>

                </div>
              </div>

            </div>
          </Container>
          <Container>
            <div className="page-section">
            <div className="page-section-media">
                  <img src={Picture3} alt="" className="feature-image" />
                </div>
              <div className="page-section-content">

                <div className="page-section-content-wrapper">
                <h3>
                    Close more clients with photography contracts templates
                  </h3>
                  <p>
                  Be it a wedding event, a studio shoot or a commercial and business shoot.<br /> <br />Our photography contracts are optimised to increase clients conversion and to protect your business.
                  </p>

                </div>
              </div>

            </div>
          </Container>
          <Container>
            <div className="page-section right">
              <div className="page-section-media">
                  <img src={Picture4} alt="" className="feature-image" />
                </div>
              <div className="page-section-content">

                <div className="page-section-content-wrapper right">
                  <h3>
                    Automatically generate and send invoices
                  </h3>
                  <p>
                  Easily manage the accounting of your photography business with automatically generated invoices, sent to your clients according to your schedule.
                  </p>

                </div>
              </div>

            </div>
          </Container>
          
            <div className="page-section page-section-large bg-pink">
              <Container>
              <div className="page-section-content">
                <h2>
                Join the exclusive MyShoots Early Beta, it’s Limited to 100 photographers only
                </h2>
                <div className="beta-button-wrapper">
                  <OutboundLink href={site.siteMetadata.typeform} type="button" className="btn-white btn">
                    Join the Early Beta
                  </OutboundLink>
                </div>
              </div>
              </Container>
            </div>

          


        </div>
        
        <footer className="page-footer">
          <div><Logo /></div>
          <p>&copy; 2020 MyShoots | All rights reserved.</p>
              
        </footer>

      </div>


    </PageContainer>
  )
}
export default IndexPage
